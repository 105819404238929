<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_prd',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Nama Lengkap Dosen',
          dataKey: 'data_master_dosen.full_name',
          order: 1,
          attributes: { rowspan: 2 }
        }, {
          label: 'Prestasi yang Ingin Dicapai',
          dataKey: 'prestasi_yang_dicapai',
          order: 2,
          attributes: { rowspan: 2 }
        }, {
          label: 'Tingkat',
          attributes: { colspan: 3 }
        }, {
          label: 'Tahun Pencapaian',
          dataKey: 'tahun_pencapaian',
          order: 6,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Bukti Prestasi',
          dataKey: 'bukti_prestasi',
          order: 7,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'Internasional',
          name: 'internasional',
          dataKey: 'internasional',
          order: 3,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Nasional',
          name: 'nasional',
          dataKey: 'nasional',
          order: 4,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Lokal',
          name: 'lokal',
          dataKey: 'lokal',
          order: 5,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Prestasi yang Ingin Dicapai',
          name: 'prestasi_yang_dicapai',
          dataKey: 'prestasi_yang_dicapai',
          type: 'textarea',
        }, {
          label: 'Internasional',
          name: 'internasional',
          dataKey(data) {
            return data.internasional ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }, {
          label: 'Nasional',
          name: 'nasional',
          dataKey(data) {
            return data.nasional ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }, {
          label: 'Lokal',
          name: 'lokal',
          dataKey(data) {
            return data.lokal ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }, {
          label: 'Tahun Pencapaian',
          name: 'tahun_pencapaian',
          dataKey: 'tahun_pencapaian',
          type: 'textarea',
        }, {
          label: 'Bukti Prestasi',
          name: 'bukti_prestasi',
          dataKey: 'bukti_prestasi',
          type: 'textarea',
        }],
        customPayload(data) {
          return {
            ...data,
            internasional: data.internasional ? 1 : 0,
            nasional: data.nasional ? 1 : 0,
            lokal: data.lokal ? 1 : 0,
          }
        }
      }
    }
  },
}
</script>